import { cabinetApi as api } from "../api";
export const addTagTypes = [
  "Company",
  "CompanyCategory",
  "ContactCadence",
  "ContactCategory",
  "ContactFieldChoice",
  "ContactFieldOrder",
  "ContactFieldTemplate",
  "ContactNote",
  "ContactTask",
  "ContactType",
  "Contact",
  "LeaderRelationship",
  "LeaderPrivateContact",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      appCrmCompaniesList: build.query<
        AppCrmCompaniesListApiResponse,
        AppCrmCompaniesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/companies/`,
          params: {
            hidden_date: queryArg.hiddenDate,
            hidden_date_gt: queryArg.hiddenDateGt,
            hidden_date_gte: queryArg.hiddenDateGte,
            hidden_date_in: queryArg.hiddenDateIn,
            hidden_date_isnull: queryArg.hiddenDateIsnull,
            hidden_date_lt: queryArg.hiddenDateLt,
            hidden_date_lte: queryArg.hiddenDateLte,
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            limit: queryArg.limit,
            name: queryArg.name,
            name_contains: queryArg.nameContains,
            name_endswith: queryArg.nameEndswith,
            name_icontains: queryArg.nameIcontains,
            name_in: queryArg.nameIn,
            name_isnull: queryArg.nameIsnull,
            name_startswith: queryArg.nameStartswith,
            offset: queryArg.offset,
            order_by: queryArg.orderBy,
            search: queryArg.search,
            search_contains: queryArg.searchContains,
            search_endswith: queryArg.searchEndswith,
            search_icontains: queryArg.searchIcontains,
            search_in: queryArg.searchIn,
            search_isnull: queryArg.searchIsnull,
            search_startswith: queryArg.searchStartswith,
          },
        }),
        providesTags: ["Company"],
      }),
      appCrmCompaniesCreate: build.mutation<
        AppCrmCompaniesCreateApiResponse,
        AppCrmCompaniesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/companies/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Company"],
      }),
      appCrmCompaniesRetrieve: build.query<
        AppCrmCompaniesRetrieveApiResponse,
        AppCrmCompaniesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/companies/${queryArg}/`,
        }),
        providesTags: ["Company"],
      }),
      appCrmCompaniesUpdate: build.mutation<
        AppCrmCompaniesUpdateApiResponse,
        AppCrmCompaniesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/companies/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.company,
        }),
        invalidatesTags: ["Company"],
      }),
      appCrmCompaniesPartialUpdate: build.mutation<
        AppCrmCompaniesPartialUpdateApiResponse,
        AppCrmCompaniesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/companies/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedCompany,
        }),
        invalidatesTags: ["Company"],
      }),
      appCrmCompaniesDestroy: build.mutation<
        AppCrmCompaniesDestroyApiResponse,
        AppCrmCompaniesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/companies/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["Company"],
      }),
      appCrmCompanyCategoriesList: build.query<
        AppCrmCompanyCategoriesListApiResponse,
        AppCrmCompanyCategoriesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/company-categories/`,
          params: {
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            limit: queryArg.limit,
            name: queryArg.name,
            name_contains: queryArg.nameContains,
            name_endswith: queryArg.nameEndswith,
            name_icontains: queryArg.nameIcontains,
            name_in: queryArg.nameIn,
            name_isnull: queryArg.nameIsnull,
            name_startswith: queryArg.nameStartswith,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["CompanyCategory"],
      }),
      appCrmCompanyCategoriesCreate: build.mutation<
        AppCrmCompanyCategoriesCreateApiResponse,
        AppCrmCompanyCategoriesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/company-categories/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["CompanyCategory"],
      }),
      appCrmCompanyCategoriesRetrieve: build.query<
        AppCrmCompanyCategoriesRetrieveApiResponse,
        AppCrmCompanyCategoriesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/company-categories/${queryArg}/`,
        }),
        providesTags: ["CompanyCategory"],
      }),
      appCrmCompanyCategoriesUpdate: build.mutation<
        AppCrmCompanyCategoriesUpdateApiResponse,
        AppCrmCompanyCategoriesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/company-categories/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.companyCategory,
        }),
        invalidatesTags: ["CompanyCategory"],
      }),
      appCrmCompanyCategoriesPartialUpdate: build.mutation<
        AppCrmCompanyCategoriesPartialUpdateApiResponse,
        AppCrmCompanyCategoriesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/company-categories/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedCompanyCategory,
        }),
        invalidatesTags: ["CompanyCategory"],
      }),
      appCrmCompanyCategoriesDestroy: build.mutation<
        AppCrmCompanyCategoriesDestroyApiResponse,
        AppCrmCompanyCategoriesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/company-categories/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["CompanyCategory"],
      }),
      appCrmContactCadenceList: build.query<
        AppCrmContactCadenceListApiResponse,
        AppCrmContactCadenceListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-cadence/`,
          params: {
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["ContactCadence"],
      }),
      appCrmContactCadenceCreate: build.mutation<
        AppCrmContactCadenceCreateApiResponse,
        AppCrmContactCadenceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-cadence/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["ContactCadence"],
      }),
      appCrmContactCadenceRetrieve: build.query<
        AppCrmContactCadenceRetrieveApiResponse,
        AppCrmContactCadenceRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-cadence/${queryArg}/`,
        }),
        providesTags: ["ContactCadence"],
      }),
      appCrmContactCadenceUpdate: build.mutation<
        AppCrmContactCadenceUpdateApiResponse,
        AppCrmContactCadenceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-cadence/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.contactCadence,
        }),
        invalidatesTags: ["ContactCadence"],
      }),
      appCrmContactCadencePartialUpdate: build.mutation<
        AppCrmContactCadencePartialUpdateApiResponse,
        AppCrmContactCadencePartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-cadence/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedContactCadence,
        }),
        invalidatesTags: ["ContactCadence"],
      }),
      appCrmContactCadenceDestroy: build.mutation<
        AppCrmContactCadenceDestroyApiResponse,
        AppCrmContactCadenceDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-cadence/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["ContactCadence"],
      }),
      appCrmContactCategoriesList: build.query<
        AppCrmContactCategoriesListApiResponse,
        AppCrmContactCategoriesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-categories/`,
          params: {
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            limit: queryArg.limit,
            name: queryArg.name,
            name_contains: queryArg.nameContains,
            name_endswith: queryArg.nameEndswith,
            name_icontains: queryArg.nameIcontains,
            name_in: queryArg.nameIn,
            name_isnull: queryArg.nameIsnull,
            name_startswith: queryArg.nameStartswith,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["ContactCategory"],
      }),
      appCrmContactCategoriesCreate: build.mutation<
        AppCrmContactCategoriesCreateApiResponse,
        AppCrmContactCategoriesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-categories/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["ContactCategory"],
      }),
      appCrmContactCategoriesRetrieve: build.query<
        AppCrmContactCategoriesRetrieveApiResponse,
        AppCrmContactCategoriesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-categories/${queryArg}/`,
        }),
        providesTags: ["ContactCategory"],
      }),
      appCrmContactCategoriesUpdate: build.mutation<
        AppCrmContactCategoriesUpdateApiResponse,
        AppCrmContactCategoriesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-categories/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.contactCategory,
        }),
        invalidatesTags: ["ContactCategory"],
      }),
      appCrmContactCategoriesPartialUpdate: build.mutation<
        AppCrmContactCategoriesPartialUpdateApiResponse,
        AppCrmContactCategoriesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-categories/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedContactCategory,
        }),
        invalidatesTags: ["ContactCategory"],
      }),
      appCrmContactCategoriesDestroy: build.mutation<
        AppCrmContactCategoriesDestroyApiResponse,
        AppCrmContactCategoriesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-categories/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["ContactCategory"],
      }),
      appCrmContactFieldChoicesList: build.query<
        AppCrmContactFieldChoicesListApiResponse,
        AppCrmContactFieldChoicesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-choices/`,
          params: {
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            label: queryArg.label,
            label_contains: queryArg.labelContains,
            label_endswith: queryArg.labelEndswith,
            label_icontains: queryArg.labelIcontains,
            label_in: queryArg.labelIn,
            label_isnull: queryArg.labelIsnull,
            label_startswith: queryArg.labelStartswith,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["ContactFieldChoice"],
      }),
      appCrmContactFieldChoicesCreate: build.mutation<
        AppCrmContactFieldChoicesCreateApiResponse,
        AppCrmContactFieldChoicesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-choices/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["ContactFieldChoice"],
      }),
      appCrmContactFieldChoicesRetrieve: build.query<
        AppCrmContactFieldChoicesRetrieveApiResponse,
        AppCrmContactFieldChoicesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-choices/${queryArg}/`,
        }),
        providesTags: ["ContactFieldChoice"],
      }),
      appCrmContactFieldChoicesUpdate: build.mutation<
        AppCrmContactFieldChoicesUpdateApiResponse,
        AppCrmContactFieldChoicesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-choices/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.contactFieldChoice,
        }),
        invalidatesTags: ["ContactFieldChoice"],
      }),
      appCrmContactFieldChoicesPartialUpdate: build.mutation<
        AppCrmContactFieldChoicesPartialUpdateApiResponse,
        AppCrmContactFieldChoicesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-choices/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedContactFieldChoice,
        }),
        invalidatesTags: ["ContactFieldChoice"],
      }),
      appCrmContactFieldChoicesDestroy: build.mutation<
        AppCrmContactFieldChoicesDestroyApiResponse,
        AppCrmContactFieldChoicesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-choices/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["ContactFieldChoice"],
      }),
      appCrmContactFieldChoicesBulkDeleteCreate: build.mutation<
        AppCrmContactFieldChoicesBulkDeleteCreateApiResponse,
        AppCrmContactFieldChoicesBulkDeleteCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-choices/bulk-delete/`,
          method: "POST",
          body: queryArg.body,
          params: {
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            label: queryArg.label,
            label_contains: queryArg.labelContains,
            label_endswith: queryArg.labelEndswith,
            label_icontains: queryArg.labelIcontains,
            label_in: queryArg.labelIn,
            label_isnull: queryArg.labelIsnull,
            label_startswith: queryArg.labelStartswith,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        invalidatesTags: ["ContactFieldChoice"],
      }),
      appCrmContactFieldChoicesBulkUpsertCreate: build.mutation<
        AppCrmContactFieldChoicesBulkUpsertCreateApiResponse,
        AppCrmContactFieldChoicesBulkUpsertCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-choices/bulk-upsert/`,
          method: "POST",
          body: queryArg.body,
          params: {
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            label: queryArg.label,
            label_contains: queryArg.labelContains,
            label_endswith: queryArg.labelEndswith,
            label_icontains: queryArg.labelIcontains,
            label_in: queryArg.labelIn,
            label_isnull: queryArg.labelIsnull,
            label_startswith: queryArg.labelStartswith,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        invalidatesTags: ["ContactFieldChoice"],
      }),
      appCrmContactFieldOrdersList: build.query<
        AppCrmContactFieldOrdersListApiResponse,
        AppCrmContactFieldOrdersListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-orders/`,
          params: {
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            limit: queryArg.limit,
            offset: queryArg.offset,
            order: queryArg.order,
            order_gt: queryArg.orderGt,
            order_gte: queryArg.orderGte,
            order_in: queryArg.orderIn,
            order_isnull: queryArg.orderIsnull,
            order_lt: queryArg.orderLt,
            order_lte: queryArg.orderLte,
          },
        }),
        providesTags: ["ContactFieldOrder"],
      }),
      appCrmContactFieldOrdersCreate: build.mutation<
        AppCrmContactFieldOrdersCreateApiResponse,
        AppCrmContactFieldOrdersCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-orders/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["ContactFieldOrder"],
      }),
      appCrmContactFieldOrdersRetrieve: build.query<
        AppCrmContactFieldOrdersRetrieveApiResponse,
        AppCrmContactFieldOrdersRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-orders/${queryArg}/`,
        }),
        providesTags: ["ContactFieldOrder"],
      }),
      appCrmContactFieldOrdersUpdate: build.mutation<
        AppCrmContactFieldOrdersUpdateApiResponse,
        AppCrmContactFieldOrdersUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-orders/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.contactFieldOrder,
        }),
        invalidatesTags: ["ContactFieldOrder"],
      }),
      appCrmContactFieldOrdersPartialUpdate: build.mutation<
        AppCrmContactFieldOrdersPartialUpdateApiResponse,
        AppCrmContactFieldOrdersPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-orders/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedContactFieldOrder,
        }),
        invalidatesTags: ["ContactFieldOrder"],
      }),
      appCrmContactFieldOrdersDestroy: build.mutation<
        AppCrmContactFieldOrdersDestroyApiResponse,
        AppCrmContactFieldOrdersDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-orders/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["ContactFieldOrder"],
      }),
      appCrmContactFieldTemplatesList: build.query<
        AppCrmContactFieldTemplatesListApiResponse,
        AppCrmContactFieldTemplatesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-templates/`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            value_type: queryArg.valueType,
            value_type_contains: queryArg.valueTypeContains,
            value_type_endswith: queryArg.valueTypeEndswith,
            value_type_icontains: queryArg.valueTypeIcontains,
            value_type_in: queryArg.valueTypeIn,
            value_type_isnull: queryArg.valueTypeIsnull,
            value_type_startswith: queryArg.valueTypeStartswith,
          },
        }),
        providesTags: ["ContactFieldTemplate"],
      }),
      appCrmContactFieldTemplatesCreate: build.mutation<
        AppCrmContactFieldTemplatesCreateApiResponse,
        AppCrmContactFieldTemplatesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-templates/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["ContactFieldTemplate"],
      }),
      appCrmContactFieldTemplatesRetrieve: build.query<
        AppCrmContactFieldTemplatesRetrieveApiResponse,
        AppCrmContactFieldTemplatesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-templates/${queryArg}/`,
        }),
        providesTags: ["ContactFieldTemplate"],
      }),
      appCrmContactFieldTemplatesUpdate: build.mutation<
        AppCrmContactFieldTemplatesUpdateApiResponse,
        AppCrmContactFieldTemplatesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-templates/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.contactFieldTemplate,
        }),
        invalidatesTags: ["ContactFieldTemplate"],
      }),
      appCrmContactFieldTemplatesPartialUpdate: build.mutation<
        AppCrmContactFieldTemplatesPartialUpdateApiResponse,
        AppCrmContactFieldTemplatesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-templates/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedContactFieldTemplate,
        }),
        invalidatesTags: ["ContactFieldTemplate"],
      }),
      appCrmContactFieldTemplatesDestroy: build.mutation<
        AppCrmContactFieldTemplatesDestroyApiResponse,
        AppCrmContactFieldTemplatesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-field-templates/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["ContactFieldTemplate"],
      }),
      appCrmContactNotesList: build.query<
        AppCrmContactNotesListApiResponse,
        AppCrmContactNotesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-notes/`,
          params: {
            allowed_users: queryArg.allowedUsers,
            allowed_users_in: queryArg.allowedUsersIn,
            allowed_users_isnull: queryArg.allowedUsersIsnull,
            body: queryArg.body,
            body_contains: queryArg.bodyContains,
            body_endswith: queryArg.bodyEndswith,
            body_icontains: queryArg.bodyIcontains,
            body_in: queryArg.bodyIn,
            body_isnull: queryArg.bodyIsnull,
            body_startswith: queryArg.bodyStartswith,
            contacts: queryArg.contacts,
            contacts_in: queryArg.contactsIn,
            contacts_isnull: queryArg.contactsIsnull,
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            limit: queryArg.limit,
            offset: queryArg.offset,
            order_by: queryArg.orderBy,
            search: queryArg.search,
            search_contains: queryArg.searchContains,
            search_endswith: queryArg.searchEndswith,
            search_icontains: queryArg.searchIcontains,
            search_in: queryArg.searchIn,
            search_isnull: queryArg.searchIsnull,
            search_startswith: queryArg.searchStartswith,
          },
        }),
        providesTags: ["ContactNote"],
      }),
      appCrmContactNotesCreate: build.mutation<
        AppCrmContactNotesCreateApiResponse,
        AppCrmContactNotesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-notes/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["ContactNote"],
      }),
      appCrmContactNotesRetrieve: build.query<
        AppCrmContactNotesRetrieveApiResponse,
        AppCrmContactNotesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-notes/${queryArg}/`,
        }),
        providesTags: ["ContactNote"],
      }),
      appCrmContactNotesUpdate: build.mutation<
        AppCrmContactNotesUpdateApiResponse,
        AppCrmContactNotesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-notes/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.contactNote,
        }),
        invalidatesTags: ["ContactNote"],
      }),
      appCrmContactNotesPartialUpdate: build.mutation<
        AppCrmContactNotesPartialUpdateApiResponse,
        AppCrmContactNotesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-notes/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedContactNote,
        }),
        invalidatesTags: ["ContactNote"],
      }),
      appCrmContactNotesDestroy: build.mutation<
        AppCrmContactNotesDestroyApiResponse,
        AppCrmContactNotesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-notes/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["ContactNote"],
      }),
      appCrmContactTasksList: build.query<
        AppCrmContactTasksListApiResponse,
        AppCrmContactTasksListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-tasks/`,
          params: {
            allowed_users: queryArg.allowedUsers,
            allowed_users_in: queryArg.allowedUsersIn,
            allowed_users_isnull: queryArg.allowedUsersIsnull,
            assignee: queryArg.assignee,
            assignee_in: queryArg.assigneeIn,
            assignee_isnull: queryArg.assigneeIsnull,
            contacts: queryArg.contacts,
            contacts_in: queryArg.contactsIn,
            contacts_isnull: queryArg.contactsIsnull,
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            limit: queryArg.limit,
            offset: queryArg.offset,
            order_by: queryArg.orderBy,
            search: queryArg.search,
            search_contains: queryArg.searchContains,
            search_endswith: queryArg.searchEndswith,
            search_icontains: queryArg.searchIcontains,
            search_in: queryArg.searchIn,
            search_isnull: queryArg.searchIsnull,
            search_startswith: queryArg.searchStartswith,
            status: queryArg.status,
            status_in: queryArg.statusIn,
            status_isnull: queryArg.statusIsnull,
            title: queryArg.title,
            title_contains: queryArg.titleContains,
            title_endswith: queryArg.titleEndswith,
            title_icontains: queryArg.titleIcontains,
            title_in: queryArg.titleIn,
            title_isnull: queryArg.titleIsnull,
            title_startswith: queryArg.titleStartswith,
          },
        }),
        providesTags: ["ContactTask"],
      }),
      appCrmContactTasksCreate: build.mutation<
        AppCrmContactTasksCreateApiResponse,
        AppCrmContactTasksCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-tasks/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["ContactTask"],
      }),
      appCrmContactTasksRetrieve: build.query<
        AppCrmContactTasksRetrieveApiResponse,
        AppCrmContactTasksRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-tasks/${queryArg}/`,
        }),
        providesTags: ["ContactTask"],
      }),
      appCrmContactTasksUpdate: build.mutation<
        AppCrmContactTasksUpdateApiResponse,
        AppCrmContactTasksUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-tasks/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.contactTask,
        }),
        invalidatesTags: ["ContactTask"],
      }),
      appCrmContactTasksPartialUpdate: build.mutation<
        AppCrmContactTasksPartialUpdateApiResponse,
        AppCrmContactTasksPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-tasks/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedContactTask,
        }),
        invalidatesTags: ["ContactTask"],
      }),
      appCrmContactTasksDestroy: build.mutation<
        AppCrmContactTasksDestroyApiResponse,
        AppCrmContactTasksDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-tasks/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["ContactTask"],
      }),
      appCrmContactTypesList: build.query<
        AppCrmContactTypesListApiResponse,
        AppCrmContactTypesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-types/`,
          params: {
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            limit: queryArg.limit,
            name: queryArg.name,
            name_contains: queryArg.nameContains,
            name_endswith: queryArg.nameEndswith,
            name_icontains: queryArg.nameIcontains,
            name_in: queryArg.nameIn,
            name_isnull: queryArg.nameIsnull,
            name_startswith: queryArg.nameStartswith,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["ContactType"],
      }),
      appCrmContactTypesCreate: build.mutation<
        AppCrmContactTypesCreateApiResponse,
        AppCrmContactTypesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-types/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["ContactType"],
      }),
      appCrmContactTypesRetrieve: build.query<
        AppCrmContactTypesRetrieveApiResponse,
        AppCrmContactTypesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-types/${queryArg}/`,
        }),
        providesTags: ["ContactType"],
      }),
      appCrmContactTypesUpdate: build.mutation<
        AppCrmContactTypesUpdateApiResponse,
        AppCrmContactTypesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-types/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.contactType,
        }),
        invalidatesTags: ["ContactType"],
      }),
      appCrmContactTypesPartialUpdate: build.mutation<
        AppCrmContactTypesPartialUpdateApiResponse,
        AppCrmContactTypesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-types/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedContactType,
        }),
        invalidatesTags: ["ContactType"],
      }),
      appCrmContactTypesDestroy: build.mutation<
        AppCrmContactTypesDestroyApiResponse,
        AppCrmContactTypesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contact-types/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["ContactType"],
      }),
      appCrmContactsList: build.query<
        AppCrmContactsListApiResponse,
        AppCrmContactsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contacts/`,
          params: {
            all_emails: queryArg.allEmails,
            all_emails_contains: queryArg.allEmailsContains,
            all_emails_overlap: queryArg.allEmailsOverlap,
            company: queryArg.company,
            company_in: queryArg.companyIn,
            company_isnull: queryArg.companyIsnull,
            contact_category: queryArg.contactCategory,
            contact_category_in: queryArg.contactCategoryIn,
            contact_category_isnull: queryArg.contactCategoryIsnull,
            contact_field_template: queryArg.contactFieldTemplate,
            contact_field_template_in: queryArg.contactFieldTemplateIn,
            contact_field_template_isnull: queryArg.contactFieldTemplateIsnull,
            contact_type: queryArg.contactType,
            contact_type_in: queryArg.contactTypeIn,
            contact_type_isnull: queryArg.contactTypeIsnull,
            created_at: queryArg.createdAt,
            created_at_gt: queryArg.createdAtGt,
            created_at_gte: queryArg.createdAtGte,
            created_at_in: queryArg.createdAtIn,
            created_at_isnull: queryArg.createdAtIsnull,
            created_at_lt: queryArg.createdAtLt,
            created_at_lte: queryArg.createdAtLte,
            field_value: queryArg.fieldValue,
            field_value_contains: queryArg.fieldValueContains,
            field_value_endswith: queryArg.fieldValueEndswith,
            field_value_gt: queryArg.fieldValueGt,
            field_value_gte: queryArg.fieldValueGte,
            field_value_icontains: queryArg.fieldValueIcontains,
            field_value_in: queryArg.fieldValueIn,
            field_value_isnull: queryArg.fieldValueIsnull,
            field_value_lt: queryArg.fieldValueLt,
            field_value_lte: queryArg.fieldValueLte,
            field_value_startswith: queryArg.fieldValueStartswith,
            hidden_date: queryArg.hiddenDate,
            hidden_date_gt: queryArg.hiddenDateGt,
            hidden_date_gte: queryArg.hiddenDateGte,
            hidden_date_in: queryArg.hiddenDateIn,
            hidden_date_isnull: queryArg.hiddenDateIsnull,
            hidden_date_lt: queryArg.hiddenDateLt,
            hidden_date_lte: queryArg.hiddenDateLte,
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            limit: queryArg.limit,
            name: queryArg.name,
            name_contains: queryArg.nameContains,
            name_endswith: queryArg.nameEndswith,
            name_icontains: queryArg.nameIcontains,
            name_in: queryArg.nameIn,
            name_isnull: queryArg.nameIsnull,
            name_startswith: queryArg.nameStartswith,
            offset: queryArg.offset,
            order_by: queryArg.orderBy,
            primary_email: queryArg.primaryEmail,
            primary_email_contains: queryArg.primaryEmailContains,
            primary_email_endswith: queryArg.primaryEmailEndswith,
            primary_email_icontains: queryArg.primaryEmailIcontains,
            primary_email_in: queryArg.primaryEmailIn,
            primary_email_isnull: queryArg.primaryEmailIsnull,
            primary_email_startswith: queryArg.primaryEmailStartswith,
            search: queryArg.search,
            search_contains: queryArg.searchContains,
            search_endswith: queryArg.searchEndswith,
            search_icontains: queryArg.searchIcontains,
            search_in: queryArg.searchIn,
            search_isnull: queryArg.searchIsnull,
            search_startswith: queryArg.searchStartswith,
            title: queryArg.title,
            title_contains: queryArg.titleContains,
            title_endswith: queryArg.titleEndswith,
            title_icontains: queryArg.titleIcontains,
            title_in: queryArg.titleIn,
            title_isnull: queryArg.titleIsnull,
            title_startswith: queryArg.titleStartswith,
            updated_at: queryArg.updatedAt,
            updated_at_gt: queryArg.updatedAtGt,
            updated_at_gte: queryArg.updatedAtGte,
            updated_at_in: queryArg.updatedAtIn,
            updated_at_isnull: queryArg.updatedAtIsnull,
            updated_at_lt: queryArg.updatedAtLt,
            updated_at_lte: queryArg.updatedAtLte,
          },
        }),
        providesTags: ["Contact"],
      }),
      appCrmContactsCreate: build.mutation<
        AppCrmContactsCreateApiResponse,
        AppCrmContactsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contacts/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Contact"],
      }),
      appCrmContactsRetrieve: build.query<
        AppCrmContactsRetrieveApiResponse,
        AppCrmContactsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contacts/${queryArg}/`,
        }),
        providesTags: ["Contact"],
      }),
      appCrmContactsUpdate: build.mutation<
        AppCrmContactsUpdateApiResponse,
        AppCrmContactsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contacts/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.contact,
        }),
        invalidatesTags: ["Contact"],
      }),
      appCrmContactsPartialUpdate: build.mutation<
        AppCrmContactsPartialUpdateApiResponse,
        AppCrmContactsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contacts/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedContact,
        }),
        invalidatesTags: ["Contact"],
      }),
      appCrmContactsDestroy: build.mutation<
        AppCrmContactsDestroyApiResponse,
        AppCrmContactsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/contacts/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["Contact"],
      }),
      appCrmLeaderRelationshipsList: build.query<
        AppCrmLeaderRelationshipsListApiResponse,
        AppCrmLeaderRelationshipsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/leader-relationships/`,
          params: {
            all_emails: queryArg.allEmails,
            all_emails_contains: queryArg.allEmailsContains,
            all_emails_overlap: queryArg.allEmailsOverlap,
            company: queryArg.company,
            company_in: queryArg.companyIn,
            company_isnull: queryArg.companyIsnull,
            contact: queryArg.contact,
            contact_category: queryArg.contactCategory,
            contact_category_in: queryArg.contactCategoryIn,
            contact_category_isnull: queryArg.contactCategoryIsnull,
            contact_created_at: queryArg.contactCreatedAt,
            contact_created_at_gt: queryArg.contactCreatedAtGt,
            contact_created_at_gte: queryArg.contactCreatedAtGte,
            contact_created_at_in: queryArg.contactCreatedAtIn,
            contact_created_at_isnull: queryArg.contactCreatedAtIsnull,
            contact_created_at_lt: queryArg.contactCreatedAtLt,
            contact_created_at_lte: queryArg.contactCreatedAtLte,
            contact_in: queryArg.contactIn,
            contact_isnull: queryArg.contactIsnull,
            contact_type: queryArg.contactType,
            contact_type_in: queryArg.contactTypeIn,
            contact_type_isnull: queryArg.contactTypeIsnull,
            contact_updated_at: queryArg.contactUpdatedAt,
            contact_updated_at_gt: queryArg.contactUpdatedAtGt,
            contact_updated_at_gte: queryArg.contactUpdatedAtGte,
            contact_updated_at_in: queryArg.contactUpdatedAtIn,
            contact_updated_at_isnull: queryArg.contactUpdatedAtIsnull,
            contact_updated_at_lt: queryArg.contactUpdatedAtLt,
            contact_updated_at_lte: queryArg.contactUpdatedAtLte,
            created_at: queryArg.createdAt,
            created_at_gt: queryArg.createdAtGt,
            created_at_gte: queryArg.createdAtGte,
            created_at_in: queryArg.createdAtIn,
            created_at_isnull: queryArg.createdAtIsnull,
            created_at_lt: queryArg.createdAtLt,
            created_at_lte: queryArg.createdAtLte,
            field_template: queryArg.fieldTemplate,
            field_template_in: queryArg.fieldTemplateIn,
            field_template_isnull: queryArg.fieldTemplateIsnull,
            field_value: queryArg.fieldValue,
            field_value_contains: queryArg.fieldValueContains,
            field_value_endswith: queryArg.fieldValueEndswith,
            field_value_gt: queryArg.fieldValueGt,
            field_value_gte: queryArg.fieldValueGte,
            field_value_icontains: queryArg.fieldValueIcontains,
            field_value_in: queryArg.fieldValueIn,
            field_value_isnull: queryArg.fieldValueIsnull,
            field_value_lt: queryArg.fieldValueLt,
            field_value_lte: queryArg.fieldValueLte,
            field_value_startswith: queryArg.fieldValueStartswith,
            hidden_date: queryArg.hiddenDate,
            hidden_date_gt: queryArg.hiddenDateGt,
            hidden_date_gte: queryArg.hiddenDateGte,
            hidden_date_in: queryArg.hiddenDateIn,
            hidden_date_isnull: queryArg.hiddenDateIsnull,
            hidden_date_lt: queryArg.hiddenDateLt,
            hidden_date_lte: queryArg.hiddenDateLte,
            id: queryArg.id,
            id_gt: queryArg.idGt,
            id_gte: queryArg.idGte,
            id_in: queryArg.idIn,
            id_isnull: queryArg.idIsnull,
            id_lt: queryArg.idLt,
            id_lte: queryArg.idLte,
            leader: queryArg.leader,
            leader_in: queryArg.leaderIn,
            leader_isnull: queryArg.leaderIsnull,
            limit: queryArg.limit,
            name: queryArg.name,
            name_contains: queryArg.nameContains,
            name_endswith: queryArg.nameEndswith,
            name_icontains: queryArg.nameIcontains,
            name_in: queryArg.nameIn,
            name_isnull: queryArg.nameIsnull,
            name_startswith: queryArg.nameStartswith,
            offset: queryArg.offset,
            order_by: queryArg.orderBy,
            primary_email: queryArg.primaryEmail,
            primary_email_contains: queryArg.primaryEmailContains,
            primary_email_endswith: queryArg.primaryEmailEndswith,
            primary_email_icontains: queryArg.primaryEmailIcontains,
            primary_email_in: queryArg.primaryEmailIn,
            primary_email_isnull: queryArg.primaryEmailIsnull,
            primary_email_startswith: queryArg.primaryEmailStartswith,
            relationship_strength: queryArg.relationshipStrength,
            relationship_strength_auto: queryArg.relationshipStrengthAuto,
            relationship_strength_auto_gt: queryArg.relationshipStrengthAutoGt,
            relationship_strength_auto_gte:
              queryArg.relationshipStrengthAutoGte,
            relationship_strength_auto_in: queryArg.relationshipStrengthAutoIn,
            relationship_strength_auto_isnull:
              queryArg.relationshipStrengthAutoIsnull,
            relationship_strength_auto_lt: queryArg.relationshipStrengthAutoLt,
            relationship_strength_auto_lte:
              queryArg.relationshipStrengthAutoLte,
            relationship_strength_in: queryArg.relationshipStrengthIn,
            relationship_strength_isnull: queryArg.relationshipStrengthIsnull,
            relationship_strength_override:
              queryArg.relationshipStrengthOverride,
            relationship_strength_override_in:
              queryArg.relationshipStrengthOverrideIn,
            relationship_strength_override_isnull:
              queryArg.relationshipStrengthOverrideIsnull,
            relationship_type: queryArg.relationshipType,
            relationship_type_in: queryArg.relationshipTypeIn,
            relationship_type_isnull: queryArg.relationshipTypeIsnull,
            source: queryArg.source,
            source_in: queryArg.sourceIn,
            source_isnull: queryArg.sourceIsnull,
            title: queryArg.title,
            title_contains: queryArg.titleContains,
            title_endswith: queryArg.titleEndswith,
            title_icontains: queryArg.titleIcontains,
            title_in: queryArg.titleIn,
            title_isnull: queryArg.titleIsnull,
            title_startswith: queryArg.titleStartswith,
            updated_at: queryArg.updatedAt,
            updated_at_gt: queryArg.updatedAtGt,
            updated_at_gte: queryArg.updatedAtGte,
            updated_at_in: queryArg.updatedAtIn,
            updated_at_isnull: queryArg.updatedAtIsnull,
            updated_at_lt: queryArg.updatedAtLt,
            updated_at_lte: queryArg.updatedAtLte,
          },
        }),
        providesTags: ["LeaderRelationship"],
      }),
      appCrmLeaderRelationshipsCreate: build.mutation<
        AppCrmLeaderRelationshipsCreateApiResponse,
        AppCrmLeaderRelationshipsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/leader-relationships/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["LeaderRelationship"],
      }),
      appCrmLeaderRelationshipsRetrieve: build.query<
        AppCrmLeaderRelationshipsRetrieveApiResponse,
        AppCrmLeaderRelationshipsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/leader-relationships/${queryArg}/`,
        }),
        providesTags: ["LeaderRelationship"],
      }),
      appCrmLeaderRelationshipsUpdate: build.mutation<
        AppCrmLeaderRelationshipsUpdateApiResponse,
        AppCrmLeaderRelationshipsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/leader-relationships/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.leaderRelationship,
        }),
        invalidatesTags: ["LeaderRelationship"],
      }),
      appCrmLeaderRelationshipsPartialUpdate: build.mutation<
        AppCrmLeaderRelationshipsPartialUpdateApiResponse,
        AppCrmLeaderRelationshipsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/leader-relationships/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedLeaderRelationship,
        }),
        invalidatesTags: ["LeaderRelationship"],
      }),
      appCrmLeaderRelationshipsDestroy: build.mutation<
        AppCrmLeaderRelationshipsDestroyApiResponse,
        AppCrmLeaderRelationshipsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/leader-relationships/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["LeaderRelationship"],
      }),
      appCrmPrivateContactsList: build.query<
        AppCrmPrivateContactsListApiResponse,
        AppCrmPrivateContactsListApiArg
      >({
        query: () => ({ url: `/api/v1_15/app-crm/private-contacts/` }),
        providesTags: ["LeaderPrivateContact"],
      }),
      appCrmPrivateContactsAddPrivateContactCreate: build.mutation<
        AppCrmPrivateContactsAddPrivateContactCreateApiResponse,
        AppCrmPrivateContactsAddPrivateContactCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/private-contacts/${queryArg.id}/add-private-contact/`,
          method: "POST",
          body: queryArg.addRemovePrivateContactInput,
        }),
        invalidatesTags: ["LeaderPrivateContact"],
      }),
      appCrmPrivateContactsRemovePrivateContactCreate: build.mutation<
        AppCrmPrivateContactsRemovePrivateContactCreateApiResponse,
        AppCrmPrivateContactsRemovePrivateContactCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/app-crm/private-contacts/${queryArg.id}/remove-private-contact/`,
          method: "POST",
          body: queryArg.addRemovePrivateContactInput,
        }),
        invalidatesTags: ["LeaderPrivateContact"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as crmGenerated };
export type AppCrmCompaniesListApiResponse =
  /** status 200  */ PaginatedCompanyList;
export type AppCrmCompaniesListApiArg = {
  hiddenDate?: string;
  /** hidden_date (greater than) */
  hiddenDateGt?: string;
  /** hidden_date (greater than or equal to) */
  hiddenDateGte?: string;
  /** hidden_date (in) */
  hiddenDateIn?: string;
  /** hidden_date (is null) */
  hiddenDateIsnull?: boolean;
  /** hidden_date (less than) */
  hiddenDateLt?: string;
  /** hidden_date (less than or equal to) */
  hiddenDateLte?: string;
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  /** Number of results to return per page. */
  limit?: number;
  name?: string;
  /** name (contains) */
  nameContains?: string;
  /** name (endswith) */
  nameEndswith?: string;
  /** name (icontains) */
  nameIcontains?: string;
  /** name (in) */
  nameIn?: string;
  /** name (is null) */
  nameIsnull?: boolean;
  /** name (startswith) */
  nameStartswith?: string;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Ordering
    
    * `id` - Id
    * `-id` - Id (descending)
    * `name` - Name
    * `-name` - Name (descending)
    * `category` - Category
    * `-category` - Category (descending) */
  orderBy?: ("-category" | "-id" | "-name" | "category" | "id" | "name")[];
  search?: string;
  /** search (contains) */
  searchContains?: string;
  /** search (endswith) */
  searchEndswith?: string;
  /** search (icontains) */
  searchIcontains?: string;
  /** search (in) */
  searchIn?: string;
  /** search (is null) */
  searchIsnull?: string;
  /** search (startswith) */
  searchStartswith?: string;
};
export type AppCrmCompaniesCreateApiResponse = /** status 201  */ Company;
export type AppCrmCompaniesCreateApiArg = Company;
export type AppCrmCompaniesRetrieveApiResponse = /** status 200  */ Company;
export type AppCrmCompaniesRetrieveApiArg =
  /** A unique integer value identifying this company. */ number;
export type AppCrmCompaniesUpdateApiResponse = /** status 200  */ Company;
export type AppCrmCompaniesUpdateApiArg = {
  /** A unique integer value identifying this company. */
  id: number;
  company: Company;
};
export type AppCrmCompaniesPartialUpdateApiResponse =
  /** status 200  */ Company;
export type AppCrmCompaniesPartialUpdateApiArg = {
  /** A unique integer value identifying this company. */
  id: number;
  patchedCompany: PatchedCompany;
};
export type AppCrmCompaniesDestroyApiResponse = unknown;
export type AppCrmCompaniesDestroyApiArg =
  /** A unique integer value identifying this company. */ number;
export type AppCrmCompanyCategoriesListApiResponse =
  /** status 200  */ PaginatedCompanyCategoryList;
export type AppCrmCompanyCategoriesListApiArg = {
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  /** Number of results to return per page. */
  limit?: number;
  name?: string;
  /** name (contains) */
  nameContains?: string;
  /** name (endswith) */
  nameEndswith?: string;
  /** name (icontains) */
  nameIcontains?: string;
  /** name (in) */
  nameIn?: string;
  /** name (is null) */
  nameIsnull?: boolean;
  /** name (startswith) */
  nameStartswith?: string;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppCrmCompanyCategoriesCreateApiResponse =
  /** status 201  */ CompanyCategory;
export type AppCrmCompanyCategoriesCreateApiArg = CompanyCategory;
export type AppCrmCompanyCategoriesRetrieveApiResponse =
  /** status 200  */ CompanyCategory;
export type AppCrmCompanyCategoriesRetrieveApiArg =
  /** A unique integer value identifying this company category. */ number;
export type AppCrmCompanyCategoriesUpdateApiResponse =
  /** status 200  */ CompanyCategory;
export type AppCrmCompanyCategoriesUpdateApiArg = {
  /** A unique integer value identifying this company category. */
  id: number;
  companyCategory: CompanyCategory;
};
export type AppCrmCompanyCategoriesPartialUpdateApiResponse =
  /** status 200  */ CompanyCategory;
export type AppCrmCompanyCategoriesPartialUpdateApiArg = {
  /** A unique integer value identifying this company category. */
  id: number;
  patchedCompanyCategory: PatchedCompanyCategory;
};
export type AppCrmCompanyCategoriesDestroyApiResponse = unknown;
export type AppCrmCompanyCategoriesDestroyApiArg =
  /** A unique integer value identifying this company category. */ number;
export type AppCrmContactCadenceListApiResponse =
  /** status 200  */ PaginatedContactCadenceList;
export type AppCrmContactCadenceListApiArg = {
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppCrmContactCadenceCreateApiResponse =
  /** status 201  */ ContactCadence;
export type AppCrmContactCadenceCreateApiArg = ContactCadence;
export type AppCrmContactCadenceRetrieveApiResponse =
  /** status 200  */ ContactCadence;
export type AppCrmContactCadenceRetrieveApiArg =
  /** A unique integer value identifying this contact cadence. */ number;
export type AppCrmContactCadenceUpdateApiResponse =
  /** status 200  */ ContactCadence;
export type AppCrmContactCadenceUpdateApiArg = {
  /** A unique integer value identifying this contact cadence. */
  id: number;
  contactCadence: ContactCadence;
};
export type AppCrmContactCadencePartialUpdateApiResponse =
  /** status 200  */ ContactCadence;
export type AppCrmContactCadencePartialUpdateApiArg = {
  /** A unique integer value identifying this contact cadence. */
  id: number;
  patchedContactCadence: PatchedContactCadence;
};
export type AppCrmContactCadenceDestroyApiResponse = unknown;
export type AppCrmContactCadenceDestroyApiArg =
  /** A unique integer value identifying this contact cadence. */ number;
export type AppCrmContactCategoriesListApiResponse =
  /** status 200  */ PaginatedContactCategoryList;
export type AppCrmContactCategoriesListApiArg = {
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  /** Number of results to return per page. */
  limit?: number;
  name?: string;
  /** name (contains) */
  nameContains?: string;
  /** name (endswith) */
  nameEndswith?: string;
  /** name (icontains) */
  nameIcontains?: string;
  /** name (in) */
  nameIn?: string;
  /** name (is null) */
  nameIsnull?: boolean;
  /** name (startswith) */
  nameStartswith?: string;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppCrmContactCategoriesCreateApiResponse =
  /** status 201  */ ContactCategory;
export type AppCrmContactCategoriesCreateApiArg = ContactCategory;
export type AppCrmContactCategoriesRetrieveApiResponse =
  /** status 200  */ ContactCategory;
export type AppCrmContactCategoriesRetrieveApiArg =
  /** A unique integer value identifying this contact category. */ number;
export type AppCrmContactCategoriesUpdateApiResponse =
  /** status 200  */ ContactCategory;
export type AppCrmContactCategoriesUpdateApiArg = {
  /** A unique integer value identifying this contact category. */
  id: number;
  contactCategory: ContactCategory;
};
export type AppCrmContactCategoriesPartialUpdateApiResponse =
  /** status 200  */ ContactCategory;
export type AppCrmContactCategoriesPartialUpdateApiArg = {
  /** A unique integer value identifying this contact category. */
  id: number;
  patchedContactCategory: PatchedContactCategory;
};
export type AppCrmContactCategoriesDestroyApiResponse = unknown;
export type AppCrmContactCategoriesDestroyApiArg =
  /** A unique integer value identifying this contact category. */ number;
export type AppCrmContactFieldChoicesListApiResponse =
  /** status 200  */ PaginatedContactFieldChoiceList;
export type AppCrmContactFieldChoicesListApiArg = {
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  label?: string;
  /** label (contains) */
  labelContains?: string;
  /** label (endswith) */
  labelEndswith?: string;
  /** label (icontains) */
  labelIcontains?: string;
  /** label (in) */
  labelIn?: string;
  /** label (is null) */
  labelIsnull?: boolean;
  /** label (startswith) */
  labelStartswith?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppCrmContactFieldChoicesCreateApiResponse =
  /** status 201  */ ContactFieldChoice;
export type AppCrmContactFieldChoicesCreateApiArg = ContactFieldChoice;
export type AppCrmContactFieldChoicesRetrieveApiResponse =
  /** status 200  */ ContactFieldChoice;
export type AppCrmContactFieldChoicesRetrieveApiArg =
  /** A unique integer value identifying this contact field choice. */ number;
export type AppCrmContactFieldChoicesUpdateApiResponse =
  /** status 200  */ ContactFieldChoice;
export type AppCrmContactFieldChoicesUpdateApiArg = {
  /** A unique integer value identifying this contact field choice. */
  id: number;
  contactFieldChoice: ContactFieldChoice;
};
export type AppCrmContactFieldChoicesPartialUpdateApiResponse =
  /** status 200  */ ContactFieldChoice;
export type AppCrmContactFieldChoicesPartialUpdateApiArg = {
  /** A unique integer value identifying this contact field choice. */
  id: number;
  patchedContactFieldChoice: PatchedContactFieldChoice;
};
export type AppCrmContactFieldChoicesDestroyApiResponse = unknown;
export type AppCrmContactFieldChoicesDestroyApiArg =
  /** A unique integer value identifying this contact field choice. */ number;
export type AppCrmContactFieldChoicesBulkDeleteCreateApiResponse =
  /** status 200  */ {
    data: number[];
    meta: {
      count?: number;
      next?: string | null;
      previous?: string | null;
      data_map?: object;
    };
  };
export type AppCrmContactFieldChoicesBulkDeleteCreateApiArg = {
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  label?: string;
  /** label (contains) */
  labelContains?: string;
  /** label (endswith) */
  labelEndswith?: string;
  /** label (icontains) */
  labelIcontains?: string;
  /** label (in) */
  labelIn?: string;
  /** label (is null) */
  labelIsnull?: boolean;
  /** label (startswith) */
  labelStartswith?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  body: number[];
};
export type AppCrmContactFieldChoicesBulkUpsertCreateApiResponse =
  /** status 200  */ PaginatedContactFieldChoiceList;
export type AppCrmContactFieldChoicesBulkUpsertCreateApiArg = {
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  label?: string;
  /** label (contains) */
  labelContains?: string;
  /** label (endswith) */
  labelEndswith?: string;
  /** label (icontains) */
  labelIcontains?: string;
  /** label (in) */
  labelIn?: string;
  /** label (is null) */
  labelIsnull?: boolean;
  /** label (startswith) */
  labelStartswith?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  body: ContactFieldChoice[];
};
export type AppCrmContactFieldOrdersListApiResponse =
  /** status 200  */ PaginatedContactFieldOrderList;
export type AppCrmContactFieldOrdersListApiArg = {
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  order?: number;
  /** order (greater than) */
  orderGt?: number;
  /** order (greater than or equal to) */
  orderGte?: number;
  /** order (in) */
  orderIn?: string;
  /** order (is null) */
  orderIsnull?: boolean;
  /** order (less than) */
  orderLt?: number;
  /** order (less than or equal to) */
  orderLte?: number;
};
export type AppCrmContactFieldOrdersCreateApiResponse =
  /** status 201  */ ContactFieldOrder;
export type AppCrmContactFieldOrdersCreateApiArg = ContactFieldOrder;
export type AppCrmContactFieldOrdersRetrieveApiResponse =
  /** status 200  */ ContactFieldOrder;
export type AppCrmContactFieldOrdersRetrieveApiArg =
  /** A unique integer value identifying this contact field order. */ number;
export type AppCrmContactFieldOrdersUpdateApiResponse =
  /** status 200  */ ContactFieldOrder;
export type AppCrmContactFieldOrdersUpdateApiArg = {
  /** A unique integer value identifying this contact field order. */
  id: number;
  contactFieldOrder: ContactFieldOrder;
};
export type AppCrmContactFieldOrdersPartialUpdateApiResponse =
  /** status 200  */ ContactFieldOrder;
export type AppCrmContactFieldOrdersPartialUpdateApiArg = {
  /** A unique integer value identifying this contact field order. */
  id: number;
  patchedContactFieldOrder: PatchedContactFieldOrder;
};
export type AppCrmContactFieldOrdersDestroyApiResponse = unknown;
export type AppCrmContactFieldOrdersDestroyApiArg =
  /** A unique integer value identifying this contact field order. */ number;
export type AppCrmContactFieldTemplatesListApiResponse =
  /** status 200  */ PaginatedContactFieldTemplateList;
export type AppCrmContactFieldTemplatesListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  valueType?: string;
  /** value_type (contains) */
  valueTypeContains?: string;
  /** value_type (endswith) */
  valueTypeEndswith?: string;
  /** value_type (icontains) */
  valueTypeIcontains?: string;
  /** value_type (in) */
  valueTypeIn?: string;
  /** value_type (is null) */
  valueTypeIsnull?: boolean;
  /** value_type (startswith) */
  valueTypeStartswith?: string;
};
export type AppCrmContactFieldTemplatesCreateApiResponse =
  /** status 201  */ ContactFieldTemplate;
export type AppCrmContactFieldTemplatesCreateApiArg = ContactFieldTemplate;
export type AppCrmContactFieldTemplatesRetrieveApiResponse =
  /** status 200  */ ContactFieldTemplate;
export type AppCrmContactFieldTemplatesRetrieveApiArg =
  /** A unique integer value identifying this contact field template. */ number;
export type AppCrmContactFieldTemplatesUpdateApiResponse =
  /** status 200  */ ContactFieldTemplate;
export type AppCrmContactFieldTemplatesUpdateApiArg = {
  /** A unique integer value identifying this contact field template. */
  id: number;
  contactFieldTemplate: ContactFieldTemplate;
};
export type AppCrmContactFieldTemplatesPartialUpdateApiResponse =
  /** status 200  */ ContactFieldTemplate;
export type AppCrmContactFieldTemplatesPartialUpdateApiArg = {
  /** A unique integer value identifying this contact field template. */
  id: number;
  patchedContactFieldTemplate: PatchedContactFieldTemplate;
};
export type AppCrmContactFieldTemplatesDestroyApiResponse = unknown;
export type AppCrmContactFieldTemplatesDestroyApiArg =
  /** A unique integer value identifying this contact field template. */ number;
export type AppCrmContactNotesListApiResponse =
  /** status 200  */ PaginatedContactNoteList;
export type AppCrmContactNotesListApiArg = {
  allowedUsers?: number[];
  /** allowed_users (in) */
  allowedUsersIn?: string;
  /** allowed_users (is null) */
  allowedUsersIsnull?: boolean;
  body?: string;
  /** body (contains) */
  bodyContains?: string;
  /** body (endswith) */
  bodyEndswith?: string;
  /** body (icontains) */
  bodyIcontains?: string;
  /** body (in) */
  bodyIn?: string;
  /** body (is null) */
  bodyIsnull?: boolean;
  /** body (startswith) */
  bodyStartswith?: string;
  contacts?: number[];
  /** contacts (in) */
  contactsIn?: string;
  /** contacts (is null) */
  contactsIsnull?: boolean;
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Ordering
    
    * `created_at` - Created at
    * `-created_at` - Created at (descending)
    * `updated_at` - Updated at
    * `-updated_at` - Updated at (descending) */
  orderBy?: ("-created_at" | "-updated_at" | "created_at" | "updated_at")[];
  search?: string;
  /** search (contains) */
  searchContains?: string;
  /** search (endswith) */
  searchEndswith?: string;
  /** search (icontains) */
  searchIcontains?: string;
  /** search (in) */
  searchIn?: string;
  /** search (is null) */
  searchIsnull?: string;
  /** search (startswith) */
  searchStartswith?: string;
};
export type AppCrmContactNotesCreateApiResponse =
  /** status 201  */ ContactNote;
export type AppCrmContactNotesCreateApiArg = ContactNote;
export type AppCrmContactNotesRetrieveApiResponse =
  /** status 200  */ ContactNote;
export type AppCrmContactNotesRetrieveApiArg =
  /** A unique integer value identifying this contact note. */ number;
export type AppCrmContactNotesUpdateApiResponse =
  /** status 200  */ ContactNote;
export type AppCrmContactNotesUpdateApiArg = {
  /** A unique integer value identifying this contact note. */
  id: number;
  contactNote: ContactNote;
};
export type AppCrmContactNotesPartialUpdateApiResponse =
  /** status 200  */ ContactNote;
export type AppCrmContactNotesPartialUpdateApiArg = {
  /** A unique integer value identifying this contact note. */
  id: number;
  patchedContactNote: PatchedContactNote;
};
export type AppCrmContactNotesDestroyApiResponse = unknown;
export type AppCrmContactNotesDestroyApiArg =
  /** A unique integer value identifying this contact note. */ number;
export type AppCrmContactTasksListApiResponse =
  /** status 200  */ PaginatedContactTaskList;
export type AppCrmContactTasksListApiArg = {
  allowedUsers?: number[];
  /** allowed_users (in) */
  allowedUsersIn?: string;
  /** allowed_users (is null) */
  allowedUsersIsnull?: boolean;
  assignee?: number;
  /** assignee (in) */
  assigneeIn?: string;
  /** assignee (is null) */
  assigneeIsnull?: boolean;
  contacts?: number[];
  /** contacts (in) */
  contactsIn?: string;
  /** contacts (is null) */
  contactsIsnull?: boolean;
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Ordering
    
    * `due_date` - Due date
    * `-due_date` - Due date (descending)
    * `created_at` - Created at
    * `-created_at` - Created at (descending)
    * `updated_at` - Updated at
    * `-updated_at` - Updated at (descending) */
  orderBy?: (
    | "-created_at"
    | "-due_date"
    | "-updated_at"
    | "created_at"
    | "due_date"
    | "updated_at"
  )[];
  search?: string;
  /** search (contains) */
  searchContains?: string;
  /** search (endswith) */
  searchEndswith?: string;
  /** search (icontains) */
  searchIcontains?: string;
  /** search (in) */
  searchIn?: string;
  /** search (is null) */
  searchIsnull?: string;
  /** search (startswith) */
  searchStartswith?: string;
  /** * `To-Do` - To-Do
   * `Completed` - Completed */
  status?: "Completed" | "To-Do";
  /** status (in) */
  statusIn?: string;
  /** status (is null) */
  statusIsnull?: boolean;
  title?: string;
  /** title (contains) */
  titleContains?: string;
  /** title (endswith) */
  titleEndswith?: string;
  /** title (icontains) */
  titleIcontains?: string;
  /** title (in) */
  titleIn?: string;
  /** title (is null) */
  titleIsnull?: boolean;
  /** title (startswith) */
  titleStartswith?: string;
};
export type AppCrmContactTasksCreateApiResponse =
  /** status 201  */ ContactTask;
export type AppCrmContactTasksCreateApiArg = ContactTask;
export type AppCrmContactTasksRetrieveApiResponse =
  /** status 200  */ ContactTask;
export type AppCrmContactTasksRetrieveApiArg =
  /** A unique integer value identifying this contact task. */ number;
export type AppCrmContactTasksUpdateApiResponse =
  /** status 200  */ ContactTask;
export type AppCrmContactTasksUpdateApiArg = {
  /** A unique integer value identifying this contact task. */
  id: number;
  contactTask: ContactTask;
};
export type AppCrmContactTasksPartialUpdateApiResponse =
  /** status 200  */ ContactTask;
export type AppCrmContactTasksPartialUpdateApiArg = {
  /** A unique integer value identifying this contact task. */
  id: number;
  patchedContactTask: PatchedContactTask;
};
export type AppCrmContactTasksDestroyApiResponse = unknown;
export type AppCrmContactTasksDestroyApiArg =
  /** A unique integer value identifying this contact task. */ number;
export type AppCrmContactTypesListApiResponse =
  /** status 200  */ PaginatedContactTypeList;
export type AppCrmContactTypesListApiArg = {
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  /** Number of results to return per page. */
  limit?: number;
  name?: string;
  /** name (contains) */
  nameContains?: string;
  /** name (endswith) */
  nameEndswith?: string;
  /** name (icontains) */
  nameIcontains?: string;
  /** name (in) */
  nameIn?: string;
  /** name (is null) */
  nameIsnull?: boolean;
  /** name (startswith) */
  nameStartswith?: string;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type AppCrmContactTypesCreateApiResponse =
  /** status 201  */ ContactType;
export type AppCrmContactTypesCreateApiArg = ContactType;
export type AppCrmContactTypesRetrieveApiResponse =
  /** status 200  */ ContactType;
export type AppCrmContactTypesRetrieveApiArg =
  /** A unique integer value identifying this contact type. */ number;
export type AppCrmContactTypesUpdateApiResponse =
  /** status 200  */ ContactType;
export type AppCrmContactTypesUpdateApiArg = {
  /** A unique integer value identifying this contact type. */
  id: number;
  contactType: ContactType;
};
export type AppCrmContactTypesPartialUpdateApiResponse =
  /** status 200  */ ContactType;
export type AppCrmContactTypesPartialUpdateApiArg = {
  /** A unique integer value identifying this contact type. */
  id: number;
  patchedContactType: PatchedContactType;
};
export type AppCrmContactTypesDestroyApiResponse = unknown;
export type AppCrmContactTypesDestroyApiArg =
  /** A unique integer value identifying this contact type. */ number;
export type AppCrmContactsListApiResponse =
  /** status 200  */ PaginatedContactList;
export type AppCrmContactsListApiArg = {
  allEmails?: string[];
  /** all_emails (contains) */
  allEmailsContains?: string[];
  /** all_emails (overlap) */
  allEmailsOverlap?: string[];
  company?: number;
  /** company (in) */
  companyIn?: string;
  /** company (is null) */
  companyIsnull?: boolean;
  contactCategory?: number;
  /** contact_category (in) */
  contactCategoryIn?: string;
  /** contact_category (is null) */
  contactCategoryIsnull?: boolean;
  contactFieldTemplate?: string;
  /** contact_field_template (in) */
  contactFieldTemplateIn?: string;
  /** contact_field_template (is null) */
  contactFieldTemplateIsnull?: boolean;
  contactType?: number;
  /** contact_type (in) */
  contactTypeIn?: string;
  /** contact_type (is null) */
  contactTypeIsnull?: boolean;
  createdAt?: string;
  /** created_at (greater than) */
  createdAtGt?: string;
  /** created_at (greater than or equal to) */
  createdAtGte?: string;
  /** created_at (in) */
  createdAtIn?: string;
  /** created_at (is null) */
  createdAtIsnull?: boolean;
  /** created_at (less than) */
  createdAtLt?: string;
  /** created_at (less than or equal to) */
  createdAtLte?: string;
  /** requires field_template to be used with it */
  fieldValue?: string;
  /** field_value (contains) */
  fieldValueContains?: string;
  /** field_value (endswith) */
  fieldValueEndswith?: string;
  /** field_value (greater than) */
  fieldValueGt?: string;
  /** field_value (greater than or equal to) */
  fieldValueGte?: string;
  /** field_value (icontains) */
  fieldValueIcontains?: string;
  /** field_value (in) */
  fieldValueIn?: string;
  /** field_value (is null) */
  fieldValueIsnull?: string;
  /** field_value (less than) */
  fieldValueLt?: string;
  /** field_value (less than or equal to) */
  fieldValueLte?: string;
  /** field_value (startswith) */
  fieldValueStartswith?: string;
  hiddenDate?: string;
  /** hidden_date (greater than) */
  hiddenDateGt?: string;
  /** hidden_date (greater than or equal to) */
  hiddenDateGte?: string;
  /** hidden_date (in) */
  hiddenDateIn?: string;
  /** hidden_date (is null) */
  hiddenDateIsnull?: boolean;
  /** hidden_date (less than) */
  hiddenDateLt?: string;
  /** hidden_date (less than or equal to) */
  hiddenDateLte?: string;
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  /** Number of results to return per page. */
  limit?: number;
  name?: string;
  /** name (contains) */
  nameContains?: string;
  /** name (endswith) */
  nameEndswith?: string;
  /** name (icontains) */
  nameIcontains?: string;
  /** name (in) */
  nameIn?: string;
  /** name (is null) */
  nameIsnull?: boolean;
  /** name (startswith) */
  nameStartswith?: string;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Ordering
    
    * `id` - Id
    * `-id` - Id (descending)
    * `primary_email` - Primary email
    * `-primary_email` - Primary email (descending)
    * `name` - Name
    * `-name` - Name (descending)
    * `title` - Title
    * `-title` - Title (descending)
    * `contact_category` - Contact category
    * `-contact_category` - Contact category (descending)
    * `contact_type` - Contact type
    * `-contact_type` - Contact type (descending)
    * `field_value` - Field value
    * `-field_value` - Field value (descending)
    * `created_at` - Created at
    * `-created_at` - Created at (descending)
    * `updated_at` - Updated at
    * `-updated_at` - Updated at (descending) */
  orderBy?: (
    | "-contact_category"
    | "-contact_type"
    | "-created_at"
    | "-field_value"
    | "-id"
    | "-name"
    | "-primary_email"
    | "-title"
    | "-updated_at"
    | "contact_category"
    | "contact_type"
    | "created_at"
    | "field_value"
    | "id"
    | "name"
    | "primary_email"
    | "title"
    | "updated_at"
  )[];
  primaryEmail?: string;
  /** primary_email (contains) */
  primaryEmailContains?: string;
  /** primary_email (endswith) */
  primaryEmailEndswith?: string;
  /** primary_email (icontains) */
  primaryEmailIcontains?: string;
  /** primary_email (in) */
  primaryEmailIn?: string;
  /** primary_email (is null) */
  primaryEmailIsnull?: boolean;
  /** primary_email (startswith) */
  primaryEmailStartswith?: string;
  search?: string;
  /** search (contains) */
  searchContains?: string;
  /** search (endswith) */
  searchEndswith?: string;
  /** search (icontains) */
  searchIcontains?: string;
  /** search (in) */
  searchIn?: string;
  /** search (is null) */
  searchIsnull?: string;
  /** search (startswith) */
  searchStartswith?: string;
  title?: string;
  /** title (contains) */
  titleContains?: string;
  /** title (endswith) */
  titleEndswith?: string;
  /** title (icontains) */
  titleIcontains?: string;
  /** title (in) */
  titleIn?: string;
  /** title (is null) */
  titleIsnull?: boolean;
  /** title (startswith) */
  titleStartswith?: string;
  updatedAt?: string;
  /** updated_at (greater than) */
  updatedAtGt?: string;
  /** updated_at (greater than or equal to) */
  updatedAtGte?: string;
  /** updated_at (in) */
  updatedAtIn?: string;
  /** updated_at (is null) */
  updatedAtIsnull?: boolean;
  /** updated_at (less than) */
  updatedAtLt?: string;
  /** updated_at (less than or equal to) */
  updatedAtLte?: string;
};
export type AppCrmContactsCreateApiResponse = /** status 201  */ Contact;
export type AppCrmContactsCreateApiArg = Contact;
export type AppCrmContactsRetrieveApiResponse = /** status 200  */ Contact;
export type AppCrmContactsRetrieveApiArg =
  /** A unique integer value identifying this contact. */ number;
export type AppCrmContactsUpdateApiResponse = /** status 200  */ Contact;
export type AppCrmContactsUpdateApiArg = {
  /** A unique integer value identifying this contact. */
  id: number;
  contact: Contact;
};
export type AppCrmContactsPartialUpdateApiResponse = /** status 200  */ Contact;
export type AppCrmContactsPartialUpdateApiArg = {
  /** A unique integer value identifying this contact. */
  id: number;
  patchedContact: PatchedContact;
};
export type AppCrmContactsDestroyApiResponse = unknown;
export type AppCrmContactsDestroyApiArg =
  /** A unique integer value identifying this contact. */ number;
export type AppCrmLeaderRelationshipsListApiResponse =
  /** status 200  */ PaginatedLeaderRelationshipList;
export type AppCrmLeaderRelationshipsListApiArg = {
  allEmails?: string[];
  /** all_emails (contains) */
  allEmailsContains?: string[];
  /** all_emails (overlap) */
  allEmailsOverlap?: string[];
  company?: number;
  /** company (in) */
  companyIn?: string;
  /** company (is null) */
  companyIsnull?: boolean;
  contact?: number;
  contactCategory?: number;
  /** contact_category (in) */
  contactCategoryIn?: string;
  /** contact_category (is null) */
  contactCategoryIsnull?: boolean;
  contactCreatedAt?: string;
  /** contact_created_at (greater than) */
  contactCreatedAtGt?: string;
  /** contact_created_at (greater than or equal to) */
  contactCreatedAtGte?: string;
  /** contact_created_at (in) */
  contactCreatedAtIn?: string;
  /** contact_created_at (is null) */
  contactCreatedAtIsnull?: boolean;
  /** contact_created_at (less than) */
  contactCreatedAtLt?: string;
  /** contact_created_at (less than or equal to) */
  contactCreatedAtLte?: string;
  /** contact (in) */
  contactIn?: string;
  /** contact (is null) */
  contactIsnull?: boolean;
  contactType?: number;
  /** contact_type (in) */
  contactTypeIn?: string;
  /** contact_type (is null) */
  contactTypeIsnull?: boolean;
  contactUpdatedAt?: string;
  /** contact_updated_at (greater than) */
  contactUpdatedAtGt?: string;
  /** contact_updated_at (greater than or equal to) */
  contactUpdatedAtGte?: string;
  /** contact_updated_at (in) */
  contactUpdatedAtIn?: string;
  /** contact_updated_at (is null) */
  contactUpdatedAtIsnull?: boolean;
  /** contact_updated_at (less than) */
  contactUpdatedAtLt?: string;
  /** contact_updated_at (less than or equal to) */
  contactUpdatedAtLte?: string;
  createdAt?: string;
  /** created_at (greater than) */
  createdAtGt?: string;
  /** created_at (greater than or equal to) */
  createdAtGte?: string;
  /** created_at (in) */
  createdAtIn?: string;
  /** created_at (is null) */
  createdAtIsnull?: boolean;
  /** created_at (less than) */
  createdAtLt?: string;
  /** created_at (less than or equal to) */
  createdAtLte?: string;
  fieldTemplate?: string;
  /** field_template (in) */
  fieldTemplateIn?: string;
  /** field_template (is null) */
  fieldTemplateIsnull?: string;
  /** requires field_template to be used with it */
  fieldValue?: string;
  /** field_value (contains) */
  fieldValueContains?: string;
  /** field_value (endswith) */
  fieldValueEndswith?: string;
  /** field_value (greater than) */
  fieldValueGt?: string;
  /** field_value (greater than or equal to) */
  fieldValueGte?: string;
  /** field_value (icontains) */
  fieldValueIcontains?: string;
  /** field_value (in) */
  fieldValueIn?: string;
  /** field_value (is null) */
  fieldValueIsnull?: string;
  /** field_value (less than) */
  fieldValueLt?: string;
  /** field_value (less than or equal to) */
  fieldValueLte?: string;
  /** field_value (startswith) */
  fieldValueStartswith?: string;
  hiddenDate?: string;
  /** hidden_date (greater than) */
  hiddenDateGt?: string;
  /** hidden_date (greater than or equal to) */
  hiddenDateGte?: string;
  /** hidden_date (in) */
  hiddenDateIn?: string;
  /** hidden_date (is null) */
  hiddenDateIsnull?: boolean;
  /** hidden_date (less than) */
  hiddenDateLt?: string;
  /** hidden_date (less than or equal to) */
  hiddenDateLte?: string;
  id?: number;
  /** id (greater than) */
  idGt?: number;
  /** id (greater than or equal to) */
  idGte?: number;
  /** id (in) */
  idIn?: string;
  /** id (is null) */
  idIsnull?: boolean;
  /** id (less than) */
  idLt?: number;
  /** id (less than or equal to) */
  idLte?: number;
  leader?: number;
  /** leader (in) */
  leaderIn?: string;
  /** leader (is null) */
  leaderIsnull?: boolean;
  /** Number of results to return per page. */
  limit?: number;
  name?: string;
  /** name (contains) */
  nameContains?: string;
  /** name (endswith) */
  nameEndswith?: string;
  /** name (icontains) */
  nameIcontains?: string;
  /** name (in) */
  nameIn?: string;
  /** name (is null) */
  nameIsnull?: boolean;
  /** name (startswith) */
  nameStartswith?: string;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Ordering
    
    * `primary_email` - Primary email
    * `-primary_email` - Primary email (descending)
    * `name` - Name
    * `-name` - Name (descending)
    * `title` - Title
    * `-title` - Title (descending)
    * `contact_created_at` - Contact created at
    * `-contact_created_at` - Contact created at (descending)
    * `contact_updated_at` - Contact updated at
    * `-contact_updated_at` - Contact updated at (descending)
    * `relationship_strength` - Relationship strength
    * `-relationship_strength` - Relationship strength (descending)
    * `relationship_strength_auto` - Relationship strength auto
    * `-relationship_strength_auto` - Relationship strength auto (descending)
    * `relationship_strength_override` - Relationship strength override
    * `-relationship_strength_override` - Relationship strength override (descending)
    * `prev_meeting_data_start` - Prev meeting data start
    * `-prev_meeting_data_start` - Prev meeting data start (descending)
    * `next_meeting_data_start` - Next meeting data start
    * `-next_meeting_data_start` - Next meeting data start (descending) */
  orderBy?: (
    | "-contact_created_at"
    | "-contact_updated_at"
    | "-name"
    | "-next_meeting_data_start"
    | "-prev_meeting_data_start"
    | "-primary_email"
    | "-relationship_strength"
    | "-relationship_strength_auto"
    | "-relationship_strength_override"
    | "-title"
    | "contact_created_at"
    | "contact_updated_at"
    | "name"
    | "next_meeting_data_start"
    | "prev_meeting_data_start"
    | "primary_email"
    | "relationship_strength"
    | "relationship_strength_auto"
    | "relationship_strength_override"
    | "title"
  )[];
  primaryEmail?: string;
  /** primary_email (contains) */
  primaryEmailContains?: string;
  /** primary_email (endswith) */
  primaryEmailEndswith?: string;
  /** primary_email (icontains) */
  primaryEmailIcontains?: string;
  /** primary_email (in) */
  primaryEmailIn?: string;
  /** primary_email (is null) */
  primaryEmailIsnull?: boolean;
  /** primary_email (startswith) */
  primaryEmailStartswith?: string;
  /** * `weak` - Weak
   * `good` - Good
   * `strong` - Strong */
  relationshipStrength?: "good" | "strong" | "weak";
  relationshipStrengthAuto?: number;
  /** relationship_strength_auto (greater than) */
  relationshipStrengthAutoGt?: number;
  /** relationship_strength_auto (greater than or equal to) */
  relationshipStrengthAutoGte?: number;
  /** relationship_strength_auto (in) */
  relationshipStrengthAutoIn?: string;
  /** relationship_strength_auto (is null) */
  relationshipStrengthAutoIsnull?: boolean;
  /** relationship_strength_auto (less than) */
  relationshipStrengthAutoLt?: number;
  /** relationship_strength_auto (less than or equal to) */
  relationshipStrengthAutoLte?: number;
  /** relationship_strength (in) */
  relationshipStrengthIn?: string;
  /** relationship_strength (is null) */
  relationshipStrengthIsnull?: string;
  /** * `weak` - Weak
   * `good` - Good
   * `strong` - Strong */
  relationshipStrengthOverride?: ("good" | "strong" | "weak") | null;
  /** relationship_strength_override (in) */
  relationshipStrengthOverrideIn?: string;
  /** relationship_strength_override (is null) */
  relationshipStrengthOverrideIsnull?: boolean;
  relationshipType?: number;
  /** relationship_type (in) */
  relationshipTypeIn?: string;
  /** relationship_type (is null) */
  relationshipTypeIsnull?: boolean;
  /** * `manual` - Manual
   * `etl` - ETL */
  source?: ("etl" | "manual") | null;
  /** source (in) */
  sourceIn?: string;
  /** source (is null) */
  sourceIsnull?: boolean;
  title?: string;
  /** title (contains) */
  titleContains?: string;
  /** title (endswith) */
  titleEndswith?: string;
  /** title (icontains) */
  titleIcontains?: string;
  /** title (in) */
  titleIn?: string;
  /** title (is null) */
  titleIsnull?: boolean;
  /** title (startswith) */
  titleStartswith?: string;
  updatedAt?: string;
  /** updated_at (greater than) */
  updatedAtGt?: string;
  /** updated_at (greater than or equal to) */
  updatedAtGte?: string;
  /** updated_at (in) */
  updatedAtIn?: string;
  /** updated_at (is null) */
  updatedAtIsnull?: boolean;
  /** updated_at (less than) */
  updatedAtLt?: string;
  /** updated_at (less than or equal to) */
  updatedAtLte?: string;
};
export type AppCrmLeaderRelationshipsCreateApiResponse =
  /** status 201  */ LeaderRelationship;
export type AppCrmLeaderRelationshipsCreateApiArg = LeaderRelationship;
export type AppCrmLeaderRelationshipsRetrieveApiResponse =
  /** status 200  */ LeaderRelationship;
export type AppCrmLeaderRelationshipsRetrieveApiArg =
  /** A unique integer value identifying this leader relationship. */ number;
export type AppCrmLeaderRelationshipsUpdateApiResponse =
  /** status 200  */ LeaderRelationship;
export type AppCrmLeaderRelationshipsUpdateApiArg = {
  /** A unique integer value identifying this leader relationship. */
  id: number;
  leaderRelationship: LeaderRelationship;
};
export type AppCrmLeaderRelationshipsPartialUpdateApiResponse =
  /** status 200  */ LeaderRelationship;
export type AppCrmLeaderRelationshipsPartialUpdateApiArg = {
  /** A unique integer value identifying this leader relationship. */
  id: number;
  patchedLeaderRelationship: PatchedLeaderRelationship;
};
export type AppCrmLeaderRelationshipsDestroyApiResponse = unknown;
export type AppCrmLeaderRelationshipsDestroyApiArg =
  /** A unique integer value identifying this leader relationship. */ number;
export type AppCrmPrivateContactsListApiResponse =
  /** status 200  */ LeaderPrivateContacts[];
export type AppCrmPrivateContactsListApiArg = void;
export type AppCrmPrivateContactsAddPrivateContactCreateApiResponse =
  /** status 200  */ LeaderPrivateContacts;
export type AppCrmPrivateContactsAddPrivateContactCreateApiArg = {
  /** A unique integer value identifying this leader. */
  id: number;
  addRemovePrivateContactInput: AddRemovePrivateContactInput;
};
export type AppCrmPrivateContactsRemovePrivateContactCreateApiResponse =
  /** status 200  */ LeaderPrivateContacts;
export type AppCrmPrivateContactsRemovePrivateContactCreateApiArg = {
  /** A unique integer value identifying this leader. */
  id: number;
  addRemovePrivateContactInput: AddRemovePrivateContactInput;
};
export type Company = {
  id: number;
  name: string;
  email_domain: string;
  notes: string | null;
  logo?: string | null;
  hidden_date?: string | null;
  organization?: number;
  category?: number | null;
  primary_contact?: number | null;
};
export type ContactDateValueMeta = {
  timezone: string;
};
export type ContactFieldMeta = {
  date_value?: ContactDateValueMeta | null;
};
export type ContactField = {
  text_value?: string | null;
  choices?: number[] | null;
  meta?: ContactFieldMeta | null;
};
export type Contact = {
  id: number;
  created_at?: string;
  updated_at?: string;
  timezone?: string | null;
  fields?: {
    [key: string]: ContactField;
  };
  tags?: string[];
  primary_email?: string | null;
  primary_email_hashed?: string | null;
  all_emails?: string[];
  all_emails_hashed?: string[];
  name: string | null;
  title?: string;
  is_resource?: boolean;
  last_observed_email?: string | null;
  last_observed_name?: string | null;
  avatar?: string | null;
  hidden_date?: string | null;
  contact_category?: number | null;
  organization?: number | null;
  contact_type?: number | null;
  company?: number | null;
  leaders: (number | null)[];
  private_leaders?: (number | null)[];
};
export type PaginatedCompanyList = {
  data: Company[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: {
      primary_contact?: {
        [key: string]: Contact;
      };
    };
  };
};
export type PatchedCompany = {
  id?: number;
  name?: string;
  email_domain?: string;
  notes?: string | null;
  logo?: string | null;
  hidden_date?: string | null;
  organization?: number;
  category?: number | null;
  primary_contact?: number | null;
};
export type CompanyCategory = {
  id: number;
  created_at?: string;
  updated_at?: string;
  name: string;
  color?: string;
  organization?: number;
};
export type PaginatedCompanyCategoryList = {
  data: CompanyCategory[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedCompanyCategory = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  name?: string;
  color?: string;
  organization?: number;
};
export type ContactCadence = {
  id: number;
  created_at?: string;
  updated_at: string;
  start_date: string;
  frequency: number;
  timeframe: string;
  created_by?: number | null;
  updated_by?: number | null;
  contact: number;
  teammates: number[];
};
export type PaginatedContactCadenceList = {
  data: ContactCadence[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedContactCadence = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  start_date?: string;
  frequency?: number;
  timeframe?: string;
  created_by?: number | null;
  updated_by?: number | null;
  contact?: number;
  teammates?: number[];
};
export type ContactCategory = {
  id: number;
  name: string;
  color?: string;
  organization?: number;
};
export type PaginatedContactCategoryList = {
  data: ContactCategory[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedContactCategory = {
  id?: number;
  name?: string;
  color?: string;
  organization?: number;
};
export type ContactFieldChoice = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  label: string;
  created_by?: number | null;
  updated_by?: number | null;
  contact_field_template: number;
};
export type PaginatedContactFieldChoiceList = {
  data: ContactFieldChoice[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedContactFieldChoice = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  label?: string;
  created_by?: number | null;
  updated_by?: number | null;
  contact_field_template?: number;
};
export type ContactFieldOrder = {
  id: number;
  created_at?: string;
  updated_at?: string;
  natural_key?: string | null;
  order: string;
  contact_field_template?: number | null;
  license: number;
};
export type PaginatedContactFieldOrderList = {
  data: ContactFieldOrder[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedContactFieldOrder = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  natural_key?: string | null;
  order?: string;
  contact_field_template?: number | null;
  license?: number;
};
export type ContactFieldTemplate = {
  id: number;
  created_at?: string;
  updated_at?: string;
  contact_field_choices: ContactFieldChoice[];
  label: string;
  /** * `short_text` - Short Text
   * `long_text` - Long Text
   * `date_type` - Date Type
   * `single_select` - Single-Select
   * `multi_select` - Multi-Select
   * `file_select` - File Select */
  value_type:
    | "short_text"
    | "long_text"
    | "date_type"
    | "single_select"
    | "multi_select"
    | "file_select";
  created_by?: number | null;
  updated_by?: number | null;
  organization: number;
};
export type PaginatedContactFieldTemplateList = {
  data: ContactFieldTemplate[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedContactFieldTemplate = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  contact_field_choices?: ContactFieldChoice[];
  label?: string;
  /** * `short_text` - Short Text
   * `long_text` - Long Text
   * `date_type` - Date Type
   * `single_select` - Single-Select
   * `multi_select` - Multi-Select
   * `file_select` - File Select */
  value_type?:
    | "short_text"
    | "long_text"
    | "date_type"
    | "single_select"
    | "multi_select"
    | "file_select";
  created_by?: number | null;
  updated_by?: number | null;
  organization?: number;
};
export type ContactNote = {
  id: number;
  contacts?: number[];
  teammates?: number[];
  company?: number[];
  allowed_users?: number[];
  created_at?: string;
  updated_at: string;
  body?: string;
  body_plain_text: string;
  is_private?: boolean;
  created_by?: number | null;
  updated_by?: number | null;
};
export type PaginatedContactNoteList = {
  data: ContactNote[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedContactNote = {
  id?: number;
  contacts?: number[];
  teammates?: number[];
  company?: number[];
  allowed_users?: number[];
  created_at?: string;
  updated_at?: string;
  body?: string;
  body_plain_text?: string;
  is_private?: boolean;
  created_by?: number | null;
  updated_by?: number | null;
};
export type ContactTask = {
  id: number;
  contacts?: number[];
  teammates?: number[];
  company?: number[];
  allowed_users?: number[];
  created_at?: string;
  updated_at: string;
  title: string;
  due_date?: string | null;
  /** * `To-Do` - To-Do
   * `Completed` - Completed */
  status?: "To-Do" | "Completed";
  is_private?: boolean;
  created_by?: number | null;
  updated_by?: number | null;
  assignee: number;
};
export type PaginatedContactTaskList = {
  data: ContactTask[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedContactTask = {
  id?: number;
  contacts?: number[];
  teammates?: number[];
  company?: number[];
  allowed_users?: number[];
  created_at?: string;
  updated_at?: string;
  title?: string;
  due_date?: string | null;
  /** * `To-Do` - To-Do
   * `Completed` - Completed */
  status?: "To-Do" | "Completed";
  is_private?: boolean;
  created_by?: number | null;
  updated_by?: number | null;
  assignee?: number;
};
export type ContactType = {
  id: number;
  name: string;
  color?: string;
};
export type PaginatedContactTypeList = {
  data: ContactType[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedContactType = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  name?: string;
  color?: string;
};
export type PaginatedContactList = {
  data: Contact[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: {
      company?: {
        [key: string]: Company;
      };
    };
  };
};
export type PatchedContact = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  timezone?: string | null;
  fields?: {
    [key: string]: ContactField;
  };
  tags?: string[];
  primary_email?: string | null;
  primary_email_hashed?: string | null;
  all_emails?: string[];
  all_emails_hashed?: string[];
  name?: string | null;
  title?: string;
  is_resource?: boolean;
  last_observed_email?: string | null;
  last_observed_name?: string | null;
  avatar?: string | null;
  hidden_date?: string | null;
  contact_category?: number | null;
  organization?: number | null;
  contact_type?: number | null;
  company?: number | null;
  leaders?: (number | null)[];
  private_leaders?: (number | null)[];
};
export type ExternalEventLocation = {
  id: number;
  name: string;
  email?: string | null;
  /** * `virtual_conference` - Web Conference
   * `phone` - Phone Call
   * `in_person` - In Person
   * `other` - Other */
  location_type?: "virtual_conference" | "phone" | "in_person" | "other";
};
export type AnalyticsEvent = {
  id: number;
  event_idr: string;
  recurring_root_event_idr: string | null;
  title: string;
  attendees: number[];
  start_date: string;
  end_date: string;
  date_scheduled: string | null;
  cabinet_create_date: string;
  cabinet_update_date: string;
  /** * `SCHEDULED` - SCHEDULED
   * `CANCELED` - CANCELED
   * `IN_PROGRESS` - IN_PROGRESS */
  status: "SCHEDULED" | "CANCELED" | "IN_PROGRESS";
  is_busy: boolean;
  external_event_locations?: ExternalEventLocation[];
  meeting: number | null;
  conference_provider: number | null;
};
export type LeaderRelationship = {
  id: number;
  created_at?: string;
  updated_at?: string;
  last_meeting_data?: {
    [key: string]: AnalyticsEvent;
  };
  relationship_strength: string;
  relationship_strength_auto: string;
  /** * `weak` - Weak
   * `good` - Good
   * `strong` - Strong */
  relationship_strength_override?: ("weak" | "good" | "strong" | null) | null;
  relationship_strength_data?: any;
  /** * `manual` - Manual
   * `etl` - ETL */
  source?: ("manual" | "etl" | null) | null;
  hidden_date?: string | null;
  next_meeting_data?: any | null;
  prev_meeting_data?: any | null;
  future_meetings?: (any | null)[];
  last_aggregated_next?: string;
  last_aggregated_prev?: string;
  leader: number;
  contact: number;
  relationship_type?: number | null;
};
export type LeaderCalendar = {
  calendar: number;
  prevent_conflict?: boolean;
  allow_calendar_analytics?: boolean;
  allow_crm?: boolean;
};
export type Leader = {
  id: number;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  company?: string | null;
  primary_assistant: number;
  industry?: number | null;
  industry_display: string;
  role?: string | null;
  pic_url?: string | null;
  country?: number | null;
  country_code: string;
  location_display: string;
  postal_code?: string | null;
  state?: string | null;
  state_short?: string | null;
  city?: string | null;
  phone_number?: string | null;
  custom?: any;
  color?: string;
  order: number;
  leader_calendars?: LeaderCalendar[];
  organization?: number | null;
};
export type PaginatedLeaderRelationshipList = {
  data: LeaderRelationship[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: {
      leader?: {
        [key: string]: Leader;
      };
      contact?: {
        [key: string]: Contact;
      };
    };
  };
};
export type PatchedLeaderRelationship = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  last_meeting_data?: {
    [key: string]: AnalyticsEvent;
  };
  relationship_strength?: string;
  relationship_strength_auto?: string;
  /** * `weak` - Weak
   * `good` - Good
   * `strong` - Strong */
  relationship_strength_override?: ("weak" | "good" | "strong" | null) | null;
  relationship_strength_data?: any;
  /** * `manual` - Manual
   * `etl` - ETL */
  source?: ("manual" | "etl" | null) | null;
  hidden_date?: string | null;
  next_meeting_data?: any | null;
  prev_meeting_data?: any | null;
  future_meetings?: (any | null)[];
  last_aggregated_next?: string;
  last_aggregated_prev?: string;
  leader?: number;
  contact?: number;
  relationship_type?: number | null;
};
export type PrivateContact = {
  id: number;
  name: string | null;
  primary_email: string | null;
};
export type LeaderPrivateContacts = {
  id: number;
  private_contacts: PrivateContact[];
};
export type AddRemovePrivateContactInput = {
  contactId: number;
};
export const {
  useAppCrmCompaniesListQuery,
  useAppCrmCompaniesCreateMutation,
  useAppCrmCompaniesRetrieveQuery,
  useAppCrmCompaniesUpdateMutation,
  useAppCrmCompaniesPartialUpdateMutation,
  useAppCrmCompaniesDestroyMutation,
  useAppCrmCompanyCategoriesListQuery,
  useAppCrmCompanyCategoriesCreateMutation,
  useAppCrmCompanyCategoriesRetrieveQuery,
  useAppCrmCompanyCategoriesUpdateMutation,
  useAppCrmCompanyCategoriesPartialUpdateMutation,
  useAppCrmCompanyCategoriesDestroyMutation,
  useAppCrmContactCadenceListQuery,
  useAppCrmContactCadenceCreateMutation,
  useAppCrmContactCadenceRetrieveQuery,
  useAppCrmContactCadenceUpdateMutation,
  useAppCrmContactCadencePartialUpdateMutation,
  useAppCrmContactCadenceDestroyMutation,
  useAppCrmContactCategoriesListQuery,
  useAppCrmContactCategoriesCreateMutation,
  useAppCrmContactCategoriesRetrieveQuery,
  useAppCrmContactCategoriesUpdateMutation,
  useAppCrmContactCategoriesPartialUpdateMutation,
  useAppCrmContactCategoriesDestroyMutation,
  useAppCrmContactFieldChoicesListQuery,
  useAppCrmContactFieldChoicesCreateMutation,
  useAppCrmContactFieldChoicesRetrieveQuery,
  useAppCrmContactFieldChoicesUpdateMutation,
  useAppCrmContactFieldChoicesPartialUpdateMutation,
  useAppCrmContactFieldChoicesDestroyMutation,
  useAppCrmContactFieldChoicesBulkDeleteCreateMutation,
  useAppCrmContactFieldChoicesBulkUpsertCreateMutation,
  useAppCrmContactFieldOrdersListQuery,
  useAppCrmContactFieldOrdersCreateMutation,
  useAppCrmContactFieldOrdersRetrieveQuery,
  useAppCrmContactFieldOrdersUpdateMutation,
  useAppCrmContactFieldOrdersPartialUpdateMutation,
  useAppCrmContactFieldOrdersDestroyMutation,
  useAppCrmContactFieldTemplatesListQuery,
  useAppCrmContactFieldTemplatesCreateMutation,
  useAppCrmContactFieldTemplatesRetrieveQuery,
  useAppCrmContactFieldTemplatesUpdateMutation,
  useAppCrmContactFieldTemplatesPartialUpdateMutation,
  useAppCrmContactFieldTemplatesDestroyMutation,
  useAppCrmContactNotesListQuery,
  useAppCrmContactNotesCreateMutation,
  useAppCrmContactNotesRetrieveQuery,
  useAppCrmContactNotesUpdateMutation,
  useAppCrmContactNotesPartialUpdateMutation,
  useAppCrmContactNotesDestroyMutation,
  useAppCrmContactTasksListQuery,
  useAppCrmContactTasksCreateMutation,
  useAppCrmContactTasksRetrieveQuery,
  useAppCrmContactTasksUpdateMutation,
  useAppCrmContactTasksPartialUpdateMutation,
  useAppCrmContactTasksDestroyMutation,
  useAppCrmContactTypesListQuery,
  useAppCrmContactTypesCreateMutation,
  useAppCrmContactTypesRetrieveQuery,
  useAppCrmContactTypesUpdateMutation,
  useAppCrmContactTypesPartialUpdateMutation,
  useAppCrmContactTypesDestroyMutation,
  useAppCrmContactsListQuery,
  useAppCrmContactsCreateMutation,
  useAppCrmContactsRetrieveQuery,
  useAppCrmContactsUpdateMutation,
  useAppCrmContactsPartialUpdateMutation,
  useAppCrmContactsDestroyMutation,
  useAppCrmLeaderRelationshipsListQuery,
  useAppCrmLeaderRelationshipsCreateMutation,
  useAppCrmLeaderRelationshipsRetrieveQuery,
  useAppCrmLeaderRelationshipsUpdateMutation,
  useAppCrmLeaderRelationshipsPartialUpdateMutation,
  useAppCrmLeaderRelationshipsDestroyMutation,
  useAppCrmPrivateContactsListQuery,
  useAppCrmPrivateContactsAddPrivateContactCreateMutation,
  useAppCrmPrivateContactsRemovePrivateContactCreateMutation,
} = injectedRtkApi;
